import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const ProductDetails = ({ product }) => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const backButton = (
    <button
      onClick={history.goBack}
      className=" py-6 flex flex-row items-center"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6667 8H3.33337"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.00004 12.6666L3.33337 7.99992L8.00004 3.33325"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span className="ml-2 text-white sm:text-xl">Back to list</span>
    </button>
  );

  const titleBlock = (
    <>
      <div className="text-3xl sm:text-4xl sm:font-bold">
        {product.productName}
      </div>
      <div className="mt-2 text-base opacity-60 font-bold ">
        By {product.brandName}
      </div>
    </>
  );

  return (
    <div className="w-full h-full ">
      <div className="block sm:hidden">{backButton}</div>
      <div className="hidden sm:block py-9"></div>
      <div className="w-full glass mx-auto rounded-2xl p-4 max-w-6xl">
        <div className="w-full mx-auto max-w-4xl">
          <div className="hidden sm:block">{backButton}</div>
          <div className="w-full h-full flex flex-row justify-center items-center sm:justify-start">
            <img
              className="w-full h-full max-h-60 rounded-lg shadow mb-4 max-w-sm object-cover"
              src={`${product.imageUrl}`}
              alt="Curated product"
            />
            <div className="hidden sm:block ml-6"> {titleBlock}</div>
          </div>
          <div className="block sm:hidden"> {titleBlock}</div>
          <div className="w-full h-full  text-left mt-4 mb-8">
            <div className="mt-8 text-base sm:text-xl">{product.review}</div>
          </div>
          <div className="mb-8">
            {!!product.internalLinks.length ? (
              <div className="font-xs sm:text-xl text-left font-semibold opacity-70 mb-3">
                Recommended retailers:
              </div>
            ) : (
              <></>
            )}
            {product.internalLinks.map(({ id, brandImageUrl, title }) => (
              <div
                style={{
                  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
                }}
                key={id}
                onClick={() =>
                  window.open(`https://styledo.co/${id}`, "_blank")
                }
                className="h-16 sm:h-24 mb-2 p-4 cursor-pointer rounded-xl flex bg-opacity-40 bg-white text-center"
              >
                <img
                  className="shadow rounded w-16 sm:w-28 ring-2 ring-gray-300"
                  src={brandImageUrl}
                  alt="Curated product"
                />
                <div className="w-full flex ml-4 sm:ml-0 justify-start sm:justify-center items-center text-base sm:text-2xl font-bold ">
                  Get from {title}
                </div>
              </div>
            ))}
            {!!product.externalLinks.length && (
              <div className="font-xs sm:text-xl text-left font-semibold opacity-70 mt-8 mb-3">
                Other Sources:
              </div>
            )}

            {product.externalLinks.map(({ title, url }) => (
              <div
                key={title + url}
                onClick={() => window.open(url, "_blank")}
                style={{
                  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
                }}
                className="h-16 sm:h-24 mb-2 p-4 cursor-pointer rounded-2xl flex flex-col bg-opacity-40 bg-white flex flex-col items-center justify-center"
              >
                <span className="text-base sm:text-xl font-bold">{title}</span>
                <span className="text-blue-500 text-xs sm:text-base underline">
                  {url.substring(0, 35)}...
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default ProductDetails;
