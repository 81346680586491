import React, { useEffect, useState } from "react";
import { Route, Link } from "react-router-dom";
import ProductDetails from "./product-details.jsx";
import SplashScreen from "./splash.jsx";
import { Helmet } from "react-helmet";
import { HideUntilLoaded, animations } from "react-animation";

const Feed = ({ alias }) => {
  const [cards, cardsSet] = useState();
  const [user, userSet] = useState({});

  useEffect(() => {
    const func = async () => {
      const res = await fetch(
        `https://api.styledoubler.com/byme/feed/${alias}`
      );

      const { avatarUrl, pageDescription, pageName, themeId, products } = await res.json();
      userSet({ avatarUrl, pageDescription, pageName, themeId });
      cardsSet(products);
    };
    func();

    window.scrollTo(0, 0);
  }, []);

  const titleScreen = (
    <div className=" font-bold text-lg sm:text-xl md:text-2xl ">
      {user.pageName}
    </div>
  );

  return !cards ? (
    <SplashScreen />
  ) : (
    <>
      <Helmet>
        <body className={`px-4 bg0${user.themeId ? user.themeId : 3}`}></body>
      </Helmet>
      <Route exact path="/:alias">
        <div className="pt-6"></div>
        <div
          style={{
            maxWidth: 1182,
          }}
          className="flex flex-wrap glass rounded-2xl sm:rounded-3xl p-4 sm:p-9 w-full mb-10 mx-auto"
        >
          <div className="flex flex-row items-start justify-center">
            <img
              className="rounded-full w-20 h-20 sm:w-52 sm:h-52"
              src={user.avatarUrl ? user.avatarUrl : "img/user-logo.svg"}
              alt="From StyleDoubler with love"
              onError={(e) => {
                e.target.src = "img/user-logo.svg";
              }}
            />

            <div className="text-left flex flex-col justify-center h-full ml-6 sm:ml-10">
              {titleScreen}
              <div className="text-xs sm:text-sm max-w-md ">
                {user.pageDescription}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            animation: animations.fadeIn,
            animationDuration: "300ms",
            maxWidth: 1182,
          }}
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-8 sm:mx-auto sm:justify-items-center"
        >
          {cards.map((c) => (
            <Card key={c.id} {...{ c, alias }} />
          ))}
        </div>
      </Route>
      <Route exact path="/:alias/:id">
        {({ match }) =>
          match ? (
            <div
              style={{
                animation: animations.fadeIn,
                animationDuration: "300ms",
              }}
            >
              <ProductDetails
                product={cards.find((c) => c.id.toString() === match.params.id)}
              />
            </div>
          ) : (
            <></>
          )
        }
      </Route>
    </>
  );
};

const Card = ({ c, alias }) => {
  return (
    <Link to={`/${alias}/${c.id}`}>
      <div
        className="flex flex-wrap glass 
      transition 
      duration-200 
      ease-in-out  
      hover:bg-white
      hover:bg-opacity-20
      transform 
      lg:hover:translate-y-1
      lg:hover:translate-x-1 
      rounded-xl
      sm:rounded-3xl
      p-4
      h-24
      sm:h-80
      w-full
      sm:w-64"
      >
        <HideUntilLoaded
          animationIn="fadeIn"
          Spinner={() => <div className="spinner">Loading...</div>}
          imageToLoad={`${c.imageUrl}`}
        >
          <div className="flex flex-row sm:flex-col">
            <img
              className="rounded-lg sm:rounded-2xl h-16 sm:h-40 w-16 sm:w-60 object-cover"
              src={`${c.imageUrl}`}
              alt="Curated product"
            />

            <div className="w-full ml-3 flex flex-col justify-start h-28">
              <div
                className="leading-3 sm:leading-8 
              text-base sm:text-2xl 
              card-title-dark py-2 font-semibold overflow-hidden"
              >
                {c.productName}
              </div>
              <div className="text-xs sm:text-base opacity-80 scale-40 card-brand-dark pb-2 font-bold pt-4">
                by {c.brandName}
              </div>
            </div>
          </div>
        </HideUntilLoaded>
      </div>
    </Link>
  );
};

export default Feed;
