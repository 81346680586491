import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(<App />, document.getElementById("root"));

reportWebVitals();

(window as any).s3 =
  "https://curated-app-data.s3.ap-southeast-1.amazonaws.com/";
