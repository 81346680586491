import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Feed from "./feed.jsx";

const StyleDoublerHomeRedirect = () => {
  useEffect(() => {
    window.location.href = "https://styledoubler.com";
  }, []);

  return <div></div>;
};

const App = () => {
  return (
    <div>
      <Router>
        <>
          <Switch>
            <Route exact path="/">
              <StyleDoublerHomeRedirect />
            </Route>
            <Route path="/:alias">
              {({ match }) => {
                return (
                  <>
                    <Feed alias={match!.params.alias} />
                  </>
                );
              }}
            </Route>
          </Switch>
        </>
      </Router>

      <div
        style={{ zIndex: -100 }}
        className="flex flex-wrap justify-around text-white items-center text-xs opacity-30 md:max-w-sm mx-auto pt-4 pb-2"
      >
        <div className="flex items-center">
          <span>powered by</span>
          <img
            src="https://assets.styledoubler.com/images/misc/styledoubler-logo.svg"
            alt="StyleDoubler"
            className="w-24"
          />
        </div>
      </div>
    </div>
  );
};

export default App;
