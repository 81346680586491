import React from "react";
import { AnimateOnChange, animations } from "react-animation";

const SplashScreen = () => {
  return (
    <div>
      <AnimateOnChange
        animationIn="bounceIn"
        animationOut="bounceOut"
        durationOut={500}
      >
        <div
          style={{
            animation: animations.fadeIn,
            animationDuration: "300ms",
          }}
        ></div>
      </AnimateOnChange>
    </div>
  );
};

export default SplashScreen;
